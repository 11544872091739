import { meAtom } from "@/atoms/users"
import { BasicButton } from "@/components/buttons/BasicButton"
import { getContractTemplates } from "@/features/contract_templates/utils/api"
import { getTaskTemplates } from "@/features/templates/api"
import { ITemplate } from "@/features/templates/types"
import { DropdownGeneric } from "@/shared/components/fields"
import { DropdownItem } from "@/shared/components/items/DropdownItem"
import { ITable, ITableBody } from "@/types/tables"
import { getTablesByLabel } from "@/utils/api/tables"
import {
  faBriefcase,
  faFileContract,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { faChevronLeft } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { useEffect, useRef, useState } from "react"
import { useRecoilValue } from "recoil"
import { createTable, deleteTable } from "../../tables/utils/api"
import { FakeTable } from "./FakeTable"
import { FakeTableBornDigital } from "./FakeTableBornDigital"
import { PotentialExtensionsBornDigital } from "./PotentialExtensionsBornDigital"
import { SalesBoostBornDigital } from "./SalesBoostBornDigital"
import { TableWorkspace } from "./TableWorkspace"

type ITableType = "tasks" | "contracts"

export const OwnerView = () => {
  const [tables, setTables] = useState<ITable[]>([])
  const [options, setOptions] = useState<any[]>([])
  const templates = useRef<Record<ITableType, ITemplate[]>>({
    tasks: [],
    contracts: [],
  })
  const me = useRecoilValue(meAtom)
  const user: any = useRecoilValue(meAtom)

  useEffect(() => {
    fetchTables()
    setOptions(defaultOptions)
  }, [])

  const fetchTables = async () => {
    const tables = await getTablesByLabel("Workspace")
    setTables(tables)
  }

  const onCreateTable = async (template: string, type: ITableType) => {
    const table: ITableBody = {
      name: "Table name",
      template,
      type,
      labels: ["Workspace"],
    }
    const createdTable = await createTable(table)
    setTables((prev) => [...prev, createdTable])
  }

  const onDeleteTable = async (uid: string) => {
    await deleteTable(uid)
    setTables((prev) => prev.filter((table) => table.uid !== uid))
  }

  const fetchTaskTemplates = async () => {
    if (!templates.current.tasks.length) {
      const taskTemplates = await getTaskTemplates()
      templates.current.tasks = taskTemplates
    }
    const options = templates.current.tasks.map((template) => ({
      title: template.name,
      onClick: (close: () => void) => {
        onCreateTable(template.uid, "tasks")
        setOptions(defaultOptions)
        close()
      },
    }))
    setOptions([
      {
        title: "Go back",
        icon: faChevronLeft,
        onClick: () => setOptions(defaultOptions),
      },
      ...options,
    ])
  }

  const fetchContractTemplates = async () => {
    if (!templates.current.contracts.length) {
      const contractTemplates = await getContractTemplates()
      templates.current.contracts = contractTemplates
    }
    const options = templates.current.contracts.map((template) => ({
      title: template.name,
      onClick: (close: () => void) => {
        onCreateTable(template.uid, "contracts")
        setOptions(defaultOptions)
        close()
      },
    }))
    setOptions([
      {
        title: "Go back",
        icon: faChevronLeft,
        onClick: () => setOptions(defaultOptions),
      },
      ...options,
    ])
  }

  const defaultOptions = [
    {
      title: "Tasks",
      icon: faBriefcase,
      onClick: fetchTaskTemplates,
    },
    {
      title: "Contracts",
      icon: faFileContract,
      onClick: fetchContractTemplates,
    },
  ]

  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <p className="mb-4 text-3xl font-medium">Workspace</p>

        <div>
          <DropdownGeneric
            collection={options}
            renderItem={({ item, close }) => (
              <DropdownItem
                className="m-1"
                title={item.title}
                onClick={() => item.onClick(close)}
                icon={item?.icon}
                selected={!!item?.icon}
              />
            )}
            button={
              <BasicButton variant="primary" as="div">
                + Add table
              </BasicButton>
            }
            autoClose={false}
          />
        </div>
      </div>
      <div className="space-y-2">
        {user?.space === "a45e50ac-015c-471b-a9a5-ef18484bb7bd" ||
        user?.space === "4b8d9331-6008-48ae-84ba-4d70ce819558" ||
        user?.space === "576bf21c-3084-4dfe-b940-33187ed4b1ac" ? (
          <FakeTable />
        ) : (
          <></>
        )}
        {user?.space === "da72b49b-4215-4f36-82c1-1f4323e2b721" && (
          <div className="space-y-8">
            <SalesBoostBornDigital />
            <FakeTableBornDigital />
            <PotentialExtensionsBornDigital />
          </div>
        )}
        {tables.map((table) => (
          <TableWorkspace
            key={table.uid}
            table={table}
            onDelete={onDeleteTable}
          />
        ))}
      </div>
      {/* <Inbox employee={me?.space_user_uid} /> */}
    </div>
  )
}
