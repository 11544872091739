import { selectedSpaceAtom } from "@/atoms/spaces"
import { meAtom } from "@/atoms/users"
import { runInRestrictedEnvironment } from "@/shared/utils/executor"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "@/utils/ImageWithFallback"
import { updateNodeContent } from "@/utils/api/nodes"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useRecoilValue } from "recoil"

export const SupplierView = () => {
  const selectedSpace = useRecoilValue(selectedSpaceAtom)
  const snippet = runInRestrictedEnvironment(selectedSpace?.welcome_snippet)
  const user = useRecoilValue(meAtom)
  const router = useRouter()

  useEffect(() => {
    if (user?.is_onboarded && !router.asPath.includes("/t/")) {
      router.push("/projects")
    } else {
      updateUser()
    }
  }, [user])

  const updateUser = async () => {
    if (!user) return
    await updateNodeContent(user.space_user_uid, {
      is_onboarded: true,
    })
  }

  if (user?.is_onboarded) {
    return null
  }

  return (
    <>
      <div className="left-0 right-0 mx-auto table w-full max-w-6xl">
        <ImageWithFallback
          src={ORGANISATION_AVATERS(selectedSpace?.organisation?.uid)}
          width={200}
          height={50}
          className="mx-auto mt-8"
          alt=""
          fallback={<></>}
        />
        {snippet}
      </div>
    </>
  )
}
