import { faChartLineUp } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Table } from "antd"
import JSConfetti from "js-confetti"
import { useEffect, useState } from "react"

const ToggleSlider = ({ initialActive }) => {
  const [isActive, setIsActive] = useState(initialActive)
  const [isActiveChanged, setIsActiveChanged] = useState(false)

  const toggleButton = () => {
    setIsActive(!isActive)
    setIsActiveChanged(true)
  }

  useEffect(() => {
    if (isActiveChanged && isActive) {
      new JSConfetti().addConfetti()
      setIsActiveChanged(false)
    }
  }, [isActive, isActiveChanged])

  return (
    <div
      onClick={toggleButton}
      className={`h-6 w-10 rounded-full bg-gray-400 p-1 ${isActive ? "bg-green-500" : ""} cursor-pointer`}
    >
      <div
        className={`h-4 w-4 transform rounded-full bg-white ${
          isActive ? "translate-x-4" : "translate-x-0"
        } transition-transform duration-300 ease-in-out`}
      ></div>
    </div>
  )
}

const fakeData = [
  {
    keywords: "Fullstack Utvikler",
    amc: <p className="text-center">12000</p>,
    cpc: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(14.6),
    cost: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(17520),
    conversions: <p className="text-center">48</p>,
    cpa: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(365),
    winrate: <p className="text-center">10</p>,
    activate: <ToggleSlider initialActive={true} />,
  },
  {
    keywords: "It Prosjektler",
    amc: <p className="text-center">9700</p>,
    cpc: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(12.0),
    cost: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(11640),
    conversions: <p className="text-center">38.8</p>,
    cpa: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(300),
    winrate: <p className="text-center">8</p>,
    activate: <ToggleSlider initialActive={false} />,
  },
  {
    keywords: "Nettverksarkitekt",
    amc: <p className="text-center">7000</p>,
    cpc: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(9.8),
    cost: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(6860),
    conversions: <p className="text-center">28</p>,
    cpa: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(245),
    winrate: <p className="text-center">6</p>,
    activate: <ToggleSlider initialActive={true} />,
  },
  {
    keywords: "Systemutvikler",
    amc: <p className="text-center">5845</p>,
    cpc: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(7.0),
    cost: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(4091.5),
    conversions: <p className="text-center">23.38</p>,
    cpa: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(175),
    winrate: <p className="text-center">5</p>,
    activate: <ToggleSlider initialActive={false} />,
  },
  {
    keywords: "Senior Designer",
    amc: <p className="text-center">4300</p>,
    cpc: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(4.0),
    cost: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(1720),
    conversions: <p className="text-center">17.2</p>,
    cpa: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(100),
    winrate: <p className="text-center">3</p>,
    activate: <ToggleSlider initialActive={true} />,
  },
  {
    keywords: "Backend utvikler",
    amc: <p className="text-center">3900</p>,
    cpc: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(3.5),
    cost: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(1365),
    conversions: <p className="text-center">15.6</p>,
    cpa: new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    }).format(87.5),
    winrate: <p className="text-center">3</p>,
    activate: <ToggleSlider initialActive={false} />,
  },
]

const columns = [
  {
    key: "keywords",
    title: "Top Performing Keywords",
    dataIndex: "keywords",
  },
  {
    key: "amc",
    title: "Avg. Monthly Clicks",
    dataIndex: "amc",
  },
  {
    key: "cpc",
    title: "CPC",
    dataIndex: "cpc",
  },
  {
    key: "cost",
    title: "Cost of 10% of the market",
    dataIndex: "cost",
  },
  {
    key: "conversions",
    title: "Potential Conversions 4%",
    dataIndex: "conversions",
  },
  {
    key: "cpa",
    title: "CPA",
    dataIndex: "cpa",
  },
  {
    key: "winrate",
    title: "Won deals with 20% closing-rate",
    dataIndex: "winrate",
  },
  {
    key: "roas",
    title: "ROAS",
    dataIndex: "roas",
  },
  {
    key: "activate",
    title: "Activate",
    dataIndex: "activate",
  },
]

export const SalesBoostBornDigital = () => {
  return (
    <div>
      <div className="mb-2 flex items-center">
        <FontAwesomeIcon icon={faChartLineUp} size="xl" className="mr-2" />
        <p className="text-2xl">Boost your sale</p>
      </div>
      <Table dataSource={fakeData} columns={columns} pagination={false} />
    </div>
  )
}
